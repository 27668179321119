import React, { useEffect, useState } from "react";
// import { Bankcardmodal } from "./Bankcardmodal";
import Searchcard from "../../assets/Searchcard.svg";
import Spinner from "../Spinner";
// import { get } from "../../utils/axiosInstance";
import { get, post } from "../../utils/axiosInstance";
import Bin from '../../assets/binnn.svg'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Close from '../../assets/Png/Closemodal.png'


import Master from "../../assets/master.svg";
import Visa from "../../assets/visa.svg";
import Toast from "../Toast";
export function Cardmodal({ switchComponent, SummaryPage }) {
  const [loader, setLoader] = useState(false);
  const [cards, setCards] = useState([]);
  useEffect(() => {
    allCards();
  }, []);


  const allCards = async () => {
    setLoader(true);
    const Token = await localStorage.getItem("token");
    get(`/card/cards`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          localStorage.setItem("myCards", JSON.stringify(res.response.data.cards));
          setCards(res.response.data.cards);
          setLoader(false);
        } else {
          console.log("====================================");
          console.log(res);
          console.log("====================================");
        }
      })
      .catch((err) => {
        console.log(err);

        setLoader(false);
      });
  };

  const handleDelete = (card_id) => {
    const Token = localStorage.getItem("token");
    var formdata = new FormData();
    formdata.append("card_id", card_id);

    post(`/card/delete`, formdata, {}, Token)
      .then((res) => {
        if ("response" in res) {
          Toast({ message: 'Card Deleted Successfully.', isError: true });
          allCards();
        } else {
          Toast({ message: 'Card Not Deleted.', isError: true });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const _renderData = () => {
    if (cards.length !== 0) {
      return (
        <div className="flex max-h-[500px]  py-2 custom-scrollbar">
          <ul className="">
            {cards.map((i, ind) => (
              <li className=" w-[100%]">
                <div className="relative lg:mt-[10px]  flex rounded-xl w-[90%]  py-4 px-4 mx-auto shadow-[0_5px_20px_-10px_#000000]">

                  {/* {
                    SummaryPage == true ? */}
                  <div className="flex items-center cursor-pointer "
                    onClick={async () => {
                      localStorage.setItem("selectedCard", JSON.stringify(i))
                      switchComponent(null)
                    }}  >
                    {/* <input
                          className="flex w-[20px] h-[20px]"
                          type="radio"
                          name="radio"
                          onClick={async () => {
                            localStorage.setItem("selectedCard", JSON.stringify(i));
                            switchComponent(null);
                          }}
                        /> */}
                    <img
                      className="w-[49px] h-[49px] ml-[18px]"
                      src={i.brand == "Visa" ? Visa : Master}
                      alt="Master Card"
                    />
                    <div className="flex flex-col ml-[16px]">
                      <p>{i.brand}</p>
                      <p>xxxx-xxxx-xxxx-{i.last_4}</p>
                    </div>
                  </div>


                  <button
                    className="absolute top-0 right-0 p-2 cursor-pointer"
                    onClick={() => handleDelete(i.card_id)}>
                    <img src={Bin} alt="Bin Img" className="w-3 h-3" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div >
      );
    } else {
      return (
        <>
          <img className="w-[157px] h-[107px] mt-10" src={Searchcard} />
          <p className="  text-[Textlitecolor] mt-[19px] md:text-[16px] text-[12px] text-center">
            Cards Not Available
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="  w-[584px]  p-20   mx-auto  ">
          <div className="border-0 rounded-lg shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">

            <div className="flex bg-Appcolor md:p-5 p-3 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="md:text-[18px] text-[15px]  w-full flex justify-center mx-auto text-white font-medium">
                Select Payment Card
              </h3>
              {/* <button className=" w-0 h-2 rounded-2xl md:mt-[-35px] mt-[-25px] md:mr-[-2px] mr-1"  onClick={() => switchComponent(null)}   >
                <span className="bg-white md:w-8 md:h-8 w-8 h-8 rounded-3xl text-black text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button> */}


              <button className="w-0 h-2 rounded-2xl lg:mt-[-35px] mt-[-32px] lg:mr-[-2px] mr-[-4px]" onClick={() => switchComponent(null)} >
                <span className="  lg:w-[34px] lg:h-[32px] w-[27px] h-[25px]  block  ">
                  <img src={Close} />
                </span>
              </button>

            </div>







            <div className="relative  my-2    mx-auto lg:max-h-[500] ">
              {loader ? (
                <div className="flex justify-center items-center mb-5">
                  <Spinner className="spinner" isLoading={loader} />
                </div>
              ) : (
                _renderData()
              )}
            </div>
            {/*footer*/}
            <div className="flex items-center  mx-auto w-[90%] justify-center pb-4  rounded-b">
              <button
                className="text-Appcolor background-blue-700 font-medium md:px-2 md:py-3 px-[6px] py-[6px] rounded-md w-[240px] border-2 md:text-sm text-[12px]"
                type="button"
                onClick={() => {
                  switchComponent("B");
                }}
              >
                {" "}
                + Add New Card
              </button>
            </div>
          </div>
        </div>
        <ToastContainer limit={1} />
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}