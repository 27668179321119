import React, { useState } from "react";
import Inputfield from "../../components/Inputfiled";
import Button from "../../components/Button";
import LoginImg from "../../assets/loginimg.svg";
import { Loginvalidationschema } from "../../utils/schema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import PasswordInput from "../Password";
import { post } from "../../utils/axiosInstance";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setSelectedVendor, setUseJourneyVendor } from "../../state/index";
import Toast from "../Toast";
import { ToastContainer } from "react-toastify";
import PopupModal from "../Modals/Popupmodal";
import PopupmodalEmail from "../Modals/PopupmodalEmail";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const useJourneyVendor = useSelector((state) => state.useJourneyVendor);

  const [showPopupModal, setShowPopupModal] = useState(false); // State to control the visibility of PopupModal
  const [showPopupModalEmail, setShowPopupModalEmail] = useState(false); // State to control the visibility of PopupModal

  // const Auth = Boolean(useSelector((state) => state.token));
  const dispatch = useDispatch();
  // const [queryParameters] = useSearchParams()
  // const showErrorToastMessage = () => {
  //   toast.error("Invalid email or password. Please try again.", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };

  // const showSuccessToastMessage = () => {
  //   toast.success("Login Successfull", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };
  const handleSubmit = (e) => {
    setLoading(true);
    post("/login", e, {}, {})
      .then((res) => {
        if ("response" in res) {
          setLoading(false);
          let responseVar = res.response.data.user;
          localStorage.setItem("token", responseVar.token);
          if (responseVar.email_verified_at === null || !responseVar.email_verified_at) {
            setShowPopupModalEmail(true)
            return;
          }
          // localStorage.setItem("token", responseVar.token);
          localStorage.setItem("user", JSON.stringify(responseVar));
          localStorage.setItem("user_type", responseVar.user_type);
          localStorage.setItem("userId", responseVar.id);

          dispatch(setUseJourneyVendor(useJourneyVendor));
          dispatch(setSelectedVendor(responseVar.default_vendor));
          dispatch(
            setLogin({
              user: responseVar,
              token: responseVar.token,
              user_type: responseVar.user_type,
              app_vendor_id: responseVar.app_vendor_id,
              user_selected_vendor_id: responseVar.user_selected_vendor_id,
            })
          );

          if (responseVar.user_type === "guest") {
            navigate("/home");
          } else {
            navigate("/vendors");
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast({
          message: err?.response?.data?.error?.messages?.[0] || "Login failed",
          isError: true,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Loginvalidationschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className=" w-full">
      <div className="w-[50%] md:block hidden bg-Appcolor h-full  fixed  ">
        <div className=" flex flex-col justify-center items-center h-full ">
          <div className="flex flex-col w-[100%] justify-center mx-auto">
            <p className="text-[36px] font-bold text-center text-white">
              Welcome To Ridewise
            </p>
            <p className="text-[30px] font-bold text-center text-white">by</p>
            <p className="text-[30px] font-bold italic  text-center text-white">
              Rentals Reimagined
            </p>
          </div>
          <img src={LoginImg} className=" w-full h-[478px]  " alt="Logo" />
        </div>
      </div>
      <img
        src={Logo}
        className="block md:hidden mx-auto h-[200px] w-[280px]"
        alt="Logo"
      />

      <div className="md:w-[50%] w-[100%]    flex justify-center items-center   mb-12 ml-auto  md:mb-0   lg:w-6/12 xl:w-6/12 ">
        <form onSubmit={formik.handleSubmit} className="w-[80%] mx-auto">
          <img
            src={Logo}
            className="hidden md:block  w-[300px] h-[250px] mx-auto "
            alt="Logo"
          />
          <h1 className="heading">Sign In</h1>
          <div className="flex flex-col justify-start md:mt-[75px] mt-[30px]">
            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[0px] mt-[20px]  text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Email"
              type="email"
              placeholder="Enter your Email"
              name="email"
              htmlFor="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              errors={formik.errors.email}
              touched={formik.touched.email}
              autoComplete="email"
            />
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>

          <div className="flex justify-end lg:mt-[41px] mt-[20px] ">
            {/* <div>
                            <Inputfield
                                inputStyle="mr-[11px] "
                                divstyle="flex flex-row-reverse"
                                Labelname="Remember me"
                                type="checkbox"
                                name="checkbox"
                                placeholder="Remember me"
                                onChange={(e) => { console.log("ee", e.target) }}
                            />
                        </div> */}
            {/* <div>
              <Inputfield
                inputStyle="mr-[11px] "
                divstyle="flex flex-row-reverse"
                Labelname="Remember me"
                type="checkbox"
                name="checkbox"
                placeholder="Remember me"
                onChange={handleCheckboxChange}
                checked={rememberMe}
              />
            </div> */}
            <div
              className="cursor-pointer"
              onClick={() => navigate("/forgotpassword")}
            >
              <a className="underline text-Appcolor hover:text-blue-600  ">
                Forgot Password
              </a>
            </div>
          </div>
          <Button
            divstyle="button w-full md:mt-[60px] mt-[30px] shadow-[0_5px_40px_-20px_#0075FF] text-white font-medium lg:p-6 p-3 text-[18px]"
            btnname="Sign In"
            type="submit"
            loading={loading}
          />

          <div className="w-full text-Textdarkcolor text-center md:my-[40px] mt-[20px]  ">
            <a>
              Don't have an account?
              <span
                className="underline  cursor-pointer ml-2"
                onClick={() => navigate("/signup")}
              >
                Create Account
              </span>
            </a>
          </div>
        </form>
      </div>

      <PopupModal showModal={showPopupModal} setShowModal={setShowPopupModal} />
      <PopupmodalEmail showModal={showPopupModalEmail} setShowModal={setShowPopupModalEmail} />
      <ToastContainer limit={1} />
    </div>
  );
};

export default Login;
