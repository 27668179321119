import React, { useState, useEffect } from "react";
import Appstore from "../../assets/Png/dummy_email.png";
import { post } from "../../utils/axiosInstance";
import Toast from "../Toast";
export function PopupmodalEmail({ showModal, setShowModal, }) {
  const [loading, setLoading] = useState(false);
  if (!showModal) return null;

  const _resendEmail = async () => {
    const token = await localStorage.getItem("token");
    setLoading(true);
    post("/email/verify/resend", {}, {}, token).then((res) => {
      console.log(res);
      setLoading(false);
      if ("response" in res) {
        setShowModal(false);
        Toast({ message: 'email sent successfully.', isError: false });
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error);
    }).finally(() => {
      setLoading(false);
      setShowModal(false);
    });

  }


  return (
    <div
      id="popup-modal"
      tabindex="-1"
      class={`${showModal ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
      <div class="relative p-4 w-full max-w-md max-h-full ">
        <div class="relative bg-white rounded-lg shadow-xl shadow-gray-500 items-center justify-center center">

          <div class="p-4 md:p-5 text-center ">
            <img
              className="h-40 w-full object-contain"
              src={Appstore}
            />
            <h3 class="mb-5 text-lg font-bold text-black dark:text-black">Please verify your email to continue.</h3>
            <div className="gap-x-10 flex justify-center ">
              <button disabled={loading} onClick={_resendEmail} data-modal-hide="popup-modal" type="button" class="text-white bg-Appcolor hover:bg-Appcolor focus:ring-4 focus:outline-none focus:bg-Appcolor dark:focus:bg-Appcolor font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                {loading ? "Loading..." : "Resend Email"}
              </button>
              <button onClick={() => setShowModal(false)} data-modal-hide="popup-modal" type="button" class="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:bg-gray-500 dark:focus:bg-gray-500 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupmodalEmail;
